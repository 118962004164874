import React, { createContext, useContext, useReducer } from 'react'

const initialState = {
	mode: 'racing',
	language: 'de',
	navState: false,
	modal: false,
}

const stateReducer = (state, payload) => {
	switch (payload.action) {
		case 'SET_NAV_OPEN_STATE':
			return { ...state, navState: payload.value }
		case 'SET_LANGUAGE':
			return { ...state, language: payload.value }
		case 'SET_MODAL':
			return { ...state, modal: payload.value }
		default:
			return state
	}
}

export const StateContext = createContext({})

export const StateContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(stateReducer, initialState)

	const StateContextStore = {
		navState: state.navState,
		navStateHandle: (foo) => dispatch({ action: 'SET_NAV_OPEN_STATE', value: foo }),
		language: state.language,
		languageHandle: (lang) => dispatch({ action: 'SET_LANGUAGE', value: lang }),
		modal: state.modal,
		modalHandle: (boo) => dispatch({ action: 'SET_MODAL', value: boo }),
		dispatch,
	}

	return <StateContext.Provider value={StateContextStore}>{children}</StateContext.Provider>
}

export const useStateContext = () => useContext(StateContext)
