import './src/styles/global.css'
import React from 'react'
// import { loadableReady } from '@loadable/component'
// import { hydrate } from 'react-dom'
import Layout from '@root/Layout'

// export const replaceHydrateFunction = () => (element, container, callback) => {
// 	loadableReady(() => {
// 		hydrate(element, container, callback)
// 	})
// }

export const onClientEntry = async () => {
	if (typeof IntersectionObserver === `undefined`) {
		await import(`react-intersection-observer`)
	}
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	let TRANSITION_DELAY = 500
	if (location.action === 'PUSH') {
		window.setTimeout(
			() =>
				window.scroll({
					left: 0,
					top: 0,
				}),
			TRANSITION_DELAY
		)
	} else {
		const savedPosition = getSavedScrollPosition(location) || [0, 0]
		window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
	}
	return false
}

export const onRouteUpdate = ({ location }) => scrollToAnchor(location)

function scrollToAnchor(location) {
	if (location && location.hash) {
		setTimeout(() => {
			const hash = location.hash.replace(/^#(\d)/, '#\\3$1')
			const item = document.querySelector(`${hash}`).offsetTop

			window.scrollTo({
				top: item - 100,
				behavior: 'smooth',
			})
		}, 800)
	}

	return true
}

export const wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>
}
