import { AnimatePresence, motion, AnimateSharedLayout } from 'framer-motion'
import React from 'react'
import { StateContextProvider } from '@context/stateContext'
import { BackToTop } from '@elements/BackToTop'
import Consent from '@elements/Consent'

const mainVariants = {
	in: {
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.08,
			staggerDirection: 1,
		},
	},
	out: {
		transition: {
			when: 'afterChildren',
			staggerChildren: 0.08,
			staggerDirection: -1,
		},
	},
}

const Layout = ({ props, children }) => {
	return (
		<StateContextProvider>
			<AnimateSharedLayout>
				<AnimatePresence exitBeforeEnter initial={true}>
					<motion.main className='relative' variants={mainVariants} key={`motion-${Math.random(0, 999)}`}>
						{children}
						<BackToTop />
						<motion.div
							className='fixed top-0 left-0 w-full min-h-screen bg-black'
							initial={{
								opacity: 1,
								zIndex: 99,
								display: 'block',
							}}
							animate={{
								opacity: 0,
								transition: {
									duration: 0.5,
									ease: [0.3, 0.01, 0.26, 0.9],
								},
								transitionEnd: {
									zIndex: -1,
									display: 'none',
								},
							}}
							exit={{
								opacity: 1,
								zIndex: 99,
								display: 'block',
								transition: {
									duration: 0.5,
									ease: [0.3, 0.01, 0.26, 0.9],
								},
							}}
						></motion.div>
					</motion.main>
				</AnimatePresence>
			</AnimateSharedLayout>
			{/* <Consent /> */}
		</StateContextProvider>
	)
}

export default Layout
