import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'

const fadeIn = {
	hidden: {
		y: '15vh',
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
		transition: {
			type: 'tween',
			ease: 'easeInOut',
			duration: 0.4,
			delay: 0.25,
		},
	},
	exit: {
		y: '-15vh',
		opacity: 0,
		transition: {
			type: 'tween',
			ease: 'easeInOut',
			duration: 0.4,
		},
	},
}

export const BackToTop = () => {
	const showOffset = 750
	const [show, setShow] = useState(false)

	const handleScroll = () => {
		if (window.pageYOffset > showOffset) {
			if (!show) setShow(true)
		} else {
			if (show) setShow(false)
		}
	}

	const handleClick = () => {
		window.scrollTo({ top: 0, behavior: `smooth` })
	}

	useEffect(() => {
		window.addEventListener(`scroll`, handleScroll)
		return () => window.removeEventListener(`scroll`, handleScroll)
	})
	if (!show) return null
	return (
		<ArrowWrapper variants={fadeIn} initial='hidden' animate='visible'>
			<TopArrow onClick={handleClick}>
				<svg className='w-full h-full' width='63' height='58' viewBox='0 0 63 58' fill='#ffffff' xmlns='http://www.w3.org/2000/svg'>
					<path d='M-1.27944e-06 29L29.0318 -2.96959e-06L31.8085 2.77364L7.52791 27.0276L63 27.0276L63 30.9724L7.52791 30.9724L31.8085 55.2264L29.0318 58L-1.27944e-06 29Z' fill='#ffffff' />
				</svg>
			</TopArrow>
		</ArrowWrapper>
	)
}
const TopArrow = styled.button`
	${tw`w-[52px] h-auto rotate-90 border-0 cursor-pointer ring-0 filter drop-shadow-burger hover:drop-shadow-burgerHover transition-all duration-300`};
`
const ArrowWrapper = styled(motion.div)`
	${tw`fixed bottom-[3vh] right-[3vw] z-[199]`}
`
